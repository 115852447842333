import React, { useState } from 'react';
// import { useItem } from '../state/GlobalState';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import { useKeyboardShortcuts } from '../lib/keyboardShortcuts';
import NoteEditor from './NoteEditor';

export interface ItemViewProps {
    itemId: string;
    autoFocus: boolean;
};

export const ItemView = (props: ItemViewProps) => {
    // const [item, saveItem] = useItem(props.itemId);
    // const [text, setText] = useState(item.text);
    const [isEditMode, setIsEditMode] = useState(false);
    
    let editorCopy: any;
    useKeyboardShortcuts({
        'e': {
          type: 'Custom',
          fn: () => {
                console.log('e pressed ' + isEditMode);
                setIsEditMode(true);
                if (editorCopy)
                    editorCopy.editing.view.focus()
          },
        },
      });
  
    // const grokItemEditor = () => {
    //     return (
    //         <div style={{minHeight: 300, minWidth: '75%', outline: 0}} id="grok-editor" >
    //             <CKEditor 
    //                 data={text}
    //                 editor={BalloonEditor}
    //                 disabled={!isEditMode}
    //                 onInit={ (editor: any) => {
    //                     editor.keystrokes.set( 'Tab', 'bulletedList' );
    //                     editor.keystrokes.set( 'Cmd+Enter', (data: any, cancel: any)=> {
    //                         console.log("command enter pressed");
    //                         setIsEditMode(false);
    //                         editor.ui.focusTracker.isFocused = false;
    //                     });
    //                     if (item.text === "") {
    //                         setIsEditMode(true)
    //                         editor.editing.view.focus()
    //                     }
    //                     editor.editing.view.document.on( 'click', ( evt: any, data: any) => {
    //                         console.log(evt);
    //                         setIsEditMode(true);
    //                     } );
    //                     editorCopy = editor
    //                 } }
    //                 config={{
    //                     placeholder: "Start thinking here...",
    //                     toolbar: ["bold", "italic", "heading", "code", "bulletedList"]
    //                 }}
    //                 onChange={ ( event: any, editor: any ) => {
    //                     const textFromEditor = editor.getData();
    //                     if (event.name === "change:data") {
    //                         setText(textFromEditor)
    //                         saveItem({...item, text})
    //                     }
    //                     editorCopy = editor
    //                 }}
    //                 onFocus={ ( event: any, editor: any ) => {
    //                     console.log("Focus called");
    //                     editor.model.change( (writer: any) => {
    //                         writer.setSelection(editor.model.document.getRoot(), 'end');
    //                     });
    //                     editorCopy = editor
    //                 }}
    //                 onBlur={ ( event: any, editor: any ) => {
    //                     console.log("On blur called");
    //                     editorCopy = editor
    //                 }}
    //             />
    //         </div>
    //     );
    // }

    return (
        <div style={{width:'50%', display:'flex', justifyContent: 'center'}}>
                <NoteEditor />
        </div>
    );
}
