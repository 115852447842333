import React, { useReducer, FunctionComponent, Dispatch, useContext } from 'react';
import { Item, newItem } from '../models/Item';

// todo: need to move from local storage to IndexedDB

const LOCAL_STORAGE_KEY = 'global-state';

interface GlobalState {
    items: { [key: string]: Item };
    inboxItemIds: string[];
}

const initialGlobalState: GlobalState = {
    items: {},
    inboxItemIds: [],
};

function getGlobalState(): GlobalState {
    const value = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (value) {
        return JSON.parse(value);
    }
    return initialGlobalState;
}

function saveGlobalState(globalState: GlobalState) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(globalState));
}

type SaveItem = {
    type: 'SaveItem',
    item: Item;
}

type Action = SaveItem;

const globalStateReducer = (state: GlobalState, action: Action) => {
    console.log('reducer called', state, action);

    let newState = {...state};

    switch (action.type) {
        case 'SaveItem':
            newState.items = {...newState.items};
            newState.items[action.item.itemId] = action.item;

            // for now, adding all items to inbox; todo: remove this
            if (newState.inboxItemIds.findIndex(x => x === action.item.itemId) === -1) {
                newState.inboxItemIds = newState.inboxItemIds.concat(action.item.itemId);
            }

            break;
    }
    
    saveGlobalState(newState);

    return newState;
};

const GlobalStateContext = React.createContext<GlobalState & { dispatch: Dispatch<Action> }>({
    ...initialGlobalState,
    dispatch: () => null
});

export const GlobalStateProvider: FunctionComponent<{}> = ({ children }) => {
    const [globalState, dispatch] = useReducer(globalStateReducer, getGlobalState());

    return (
        <GlobalStateContext.Provider value={{ ...globalState, dispatch }}>
            {children}
        </GlobalStateContext.Provider>
    );
}

export function useInboxItemIds(): string[] {
    const {inboxItemIds} = useContext(GlobalStateContext);
    return inboxItemIds;
}

export function useItem(itemId: string): [Item, (item: Item) => void] {
    const {items, dispatch} = useContext(GlobalStateContext);
    const saveItem = (item: Item) => dispatch({
        type: 'SaveItem',
        item
    });
    const item = items[itemId] || newItem(itemId);
    return [item, saveItem];
}
