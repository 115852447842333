import { v4 as uuidv4 } from 'uuid';

export interface Item {
    itemId: string;
    text: string;
    type: 'Note' | 'Task';
    topics: string[];
    reminders: Reminder[];
    parentItemId?: string;
    childItemIds: string[];
}

export interface TimeReminder {
    type: 'Time';
    ts: number;
}

export interface DeviceReminder {
    type: 'Device';
    device: 'Mobile' | 'Desktop' | 'Tablet';
}

export interface LocationReminder {
    type: 'Location';
    location: string;
}

type Reminder = TimeReminder | DeviceReminder | LocationReminder

export function newItem(itemId: string, parentItemId?: string): Item {
    return {
        itemId,
        text: '',
        type: 'Note',
        topics: [],
        reminders: [],
        parentItemId,
        childItemIds: []
    }
}

export function newItemId() {
    return uuidv4();
}
