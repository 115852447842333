import React from 'react';
import './App.css';
import InboxScreen from './screens/InboxScreen';
import ItemScreen from './screens/ItemScreen';
import { GlobalStateProvider } from './state/GlobalState';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { globalKeyboardShortcuts, useKeyboardShortcuts } from './lib/keyboardShortcuts';

export default () => {
  return (
    <GlobalStateProvider>
      <Router>
        <div className="App">
          <header className="App-header">
          </header>
          <Screens/>
        </div>
      </Router>
    </GlobalStateProvider>
  );
}

const Screens = () => {
  useKeyboardShortcuts(globalKeyboardShortcuts);

  return (
    <Switch>
      <Route path="/" exact><InboxScreen /></Route>
      <Route path="/item" exact><ItemScreen /></Route>
      <Route path="/item/:itemId"><ItemScreen /></Route>
      <Route><h4>Not Found</h4></Route>
    </Switch>
  )
}
