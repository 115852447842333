import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Mousetrap = require("mousetrap");

export type KeyboardShortcuts = { [key: string]: ShortcutAction };

export interface NavigateAction {
  type: 'Navigate';
  path: string;   // if the keyboard shortcut should move to new location
  hint?: string;  // to be shown on mouseover etc.
}

export interface CustomAction {
  type: 'Custom';
  fn: () => void;
  hint?: string;
}

export type ShortcutAction = CustomAction | NavigateAction;

export const useKeyboardShortcuts = (keyboardShortcuts: KeyboardShortcuts) => {
  const history = useHistory();

  useEffect(() => {
    Object.keys(keyboardShortcuts).forEach(key => {
      const action = keyboardShortcuts[key];
      Mousetrap.bind(key, () => {
        switch (action.type) {
          case 'Navigate':
            history.push(action.path);
            break;
          case 'Custom':
            action.fn();
            break;
        }
        return false
      });
    });

    return () => {
      Object.keys(keyboardShortcuts).forEach(key => {
        Mousetrap.unbind(key);
      })
    }
  }, [keyboardShortcuts, history]);
}

// todo: doesn't work because there is no location state; need to figure out how to populate this automatically
export const useGoBackOnEscape = () => {
  const location = useLocation<{ from?: {pathname?: string} }>();
  const history = useHistory();

  useKeyboardShortcuts({
    'esc': {
      type: 'Custom',
      fn: () => {
        console.log(location);
        if (location.state?.from?.pathname) {
          history.goBack();
        }
      },
    },
  })
}

export const globalKeyboardShortcuts: KeyboardShortcuts = {
  'g i': { type: 'Navigate', path: '/' },
  'c': { type: 'Navigate', path: '/item' },
}
