import React, { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { Node, Editor, Transforms, Range, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSelected,
  useFocused,
} from 'slate-react'
import ReactDOM from 'react-dom'

export const Portal = ({ children }: any) => {
    return ReactDOM.createPortal(children, document.body)
}

const NoteEditor = () => {
  const ref = useRef<HTMLDivElement | null>()
  const [value, setValue] = useState<Node[]>(initialValue)
  const [target, setTarget] = useState<Range | undefined>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  )

  const matchedTopics = topics.filter(c =>
    c.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10)

  const onKeyDown = useCallback(
    event => {
      if (target) {
        console.log(target);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= matchedTopics.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? matchedTopics.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            insertMention(editor, matchedTopics[index])
            setTarget(undefined)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
			break
		  case ']':
			event.preventDefault()
			if (!topics.includes(search)) topics.push(search)
			Transforms.select(editor, target)
			insertMention(editor, search);
            setTarget(undefined)
			break
        }
      }
    },
    [index, search, target, editor, matchedTopics]
  )

  useEffect(() => {
    if (target && matchedTopics.length > 0) {
      const el = ref.current
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el!!.style.top = `${rect.top + window.pageYOffset + 24}px`
      el!!.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [matchedTopics.length, editor, index, search, target])

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={value => {
        setValue(value)
        const { selection } = editor
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection)
          const wordBefore = Editor.before(editor, start, { unit: 'word' })
          const before = wordBefore && Editor.before(editor, wordBefore)
          const beforeRange = before && Editor.range(editor, before, start)
          const beforeText = beforeRange && Editor.string(editor, beforeRange)
          const beforeMatch = beforeText && beforeText.match(/^\[(\w+)$/)
          const after = Editor.after(editor, start)
          const afterRange = Editor.range(editor, start, after)
          const afterText = Editor.string(editor, afterRange)
          const afterMatch = afterText.match(/^(\s|$|\])/)
          if (beforeMatch && afterMatch) {
            //   console.log("beforeRange", beforeRange);
			//   console.log("beforeMatch", beforeMatch);
			//   console.log("beforeText",beforeText);
			//   console.log("afterText", afterText);
			//   console.log("afterMatch", afterText);
            setTarget(beforeRange)
            setSearch(beforeMatch[1])
            setIndex(0)
            return
          }
        }

        setTarget(undefined)
      }}
    >
      <Editable
        renderElement={renderElement}
        onKeyDown={onKeyDown}
        placeholder="Enter some text..."
      />
      {target && (
        <Portal>
          <div
          //@ts-ignore
            ref={ref}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 1,
              padding: '3px',
              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 1px 5px rgba(0,0,0,.2)',
            }}
          >
            {matchedTopics.map((char, i) => (
              <div
                key={char}
                style={{
                  padding: '1px 3px',
                  borderRadius: '3px',
                  background: i === index ? '#B4D5FF' : 'transparent',
                }}
              >
                {char}
              </div>
            ))}
			<div>
			  {search}
		  	</div>
          </div>
		  
        </Portal>
      )}
    </Slate>
  )
}

const withMentions = (editor: any) => {
  const { isInline, isVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element: any) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  return editor
}

const insertMention = (editor: Editor, character: any) => {
  const mention = { type: 'mention', character, children: [{ text: '' }] }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}

const Element = (props: { attributes: any; children: any; element: any }) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'mention':
      return <MentionElement {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const MentionElement = ({ attributes, children, element }: any) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        backgroundColor: '#eee',
        fontSize: '0.9em',
        boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
      }}
    >
      {element.character}
      {children}
    </span>
  )
}

const initialValue = [
  {
    children: [
        {
            text: 'Think here'
        },
    ],
  }
]

const topics = [
    "sid",
    "sid_reddy",
    "abhilash",
    "abhilash_pillai_2",
    "ravi",
    "ravi_teja"
]

export default NoteEditor
