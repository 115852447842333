import React from 'react';
import { Link } from 'react-router-dom';
import { useItem } from '../state/GlobalState';

export type ItemViewProps = { itemId: string };

export const ItemSummaryView = (props: ItemViewProps) => {
    const [item] = useItem(props.itemId);

    return (
        <div>
            <Link to={`/item/${item.itemId}`}>
                {item.text}
            </Link>
        </div>
    );
}
