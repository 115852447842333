import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ItemView } from '../components/ItemView';
import { useGoBackOnEscape } from '../lib/keyboardShortcuts';
import { newItemId } from '../models/Item';

export default () => {
    const { itemId } = useParams<{itemId?: string}>();
    const history = useHistory();
    useGoBackOnEscape();

    if (!itemId) {
        history.replace(`/item/${newItemId()}`);
        return null;
    }

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <ItemScreen itemId={itemId}/>
        </div>
    );
}

interface ItemScreenProps {
    itemId: string;
}

const ItemScreen = ({itemId}: ItemScreenProps) => {
    return <ItemView itemId={itemId} autoFocus={true}/>;
}
