import React from 'react';
import { useInboxItemIds } from '../state/GlobalState';
import { ItemSummaryView } from '../components/ItemSummaryView';

export default () => {
    return (
        <div>
            <InboxView/>
        </div>
    );
}

function InboxView() {
    const itemIds = useInboxItemIds();

    return (
        <div>
            {itemIds.map(itemId => <ItemSummaryView key={itemId} itemId={itemId}/>)}
        </div>
    );
}
